<template>

<div>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">Database</li>
    </ol>

    <b-dropdown variant="primary" text="Table" class="ml-3 childClass">
        <b-dropdown-item
            v-for="table in this.tables"
            :key="table"
            @click="getTable(table)">
                {{ table }}
        </b-dropdown-item>
    </b-dropdown>
    <b-button variant="info" @click="copyItems()"><b-icon icon="clipboard" /> Copy Items to Clipboard</b-button>

  <BMSTable
    :items="items"
    :fields="fields"
  >
  </BMSTable>

</div>
</template>

<script>

import { listTables, listTable, describeTable } from '@/api/database.api'
import BMSTable from '@/components/BMSTable';
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
    data() {
        return {
            fields: [],
            items: [],
            tables: [],
        }
    },
    mounted() {
        this.getTables();
    },
    methods: {
        getTables() {
            this.call(
                listTables(),
                (res) => {
                    this.tables.push(...res.data.tables)
                }
            )
        },
        getTable(tableName) {
            this.call(
                describeTable(tableName),
                (res) => {
                    this.fields.splice(0)
                    this.fields.push(...res.data.columns)
                }
            )
            this.call(
                listTable(tableName),
                (res) => {
                    this.items.splice(0)
                    this.items.push(...res.data.rows)
                }
            )
        },
        async copyItems() {
            let order = this.fields
            let items = [order].concat(this.items.map(it => {
                return order.map((key) => it[key])
                //return [it[order[0]], it[order[1]], it[order[2]]]
            })).map(it => {
                return it.join(',')
            }).join('\n')
            try {
                await navigator.clipboard.writeText(items);
                this.$awn.success('Copied');
            } catch($e) {
                this.$awn.alert('Copy failed');
                console.log($e)
            }
        },
    },
    components: {
        BMSTable
    }
}
</script>
