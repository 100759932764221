import httpClient from './httpClient';

const END_POINT = '/database';

const listTables = () => httpClient.get(END_POINT + '/tables');
const describeTable = (name) => httpClient.get(END_POINT + '/' + name + '/describe')
const listTable = (name) => httpClient.get(END_POINT + '/' + name + '/list')

export {
    listTables,
    describeTable,
    listTable
}

